import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Icon, Button, Grid } from 'semantic-ui-react'
import config from '../../config';

class PrevNext extends Component {
  previousLink = id => {
    if (this.props.data[id].previous === null) {
      return this.props.data[12].node
    } else {
      return this.props.data[id].previous
    }
  }

  upNextLink = id => {
    if (this.props.data[id].next === null) {
      return this.props.data[0].node
    } else {
      return this.props.data[id].next
    }
  }

  render() {
      const buttonCss = {
          backgroundColor: config.colors.primary,
          color: config.colors.tertiary,
          height: '60px',
          fontSize: '1.2rem'
      }
    return (
      <Grid>
          <Grid.Column width={8}>
        <Link to={this.previousLink(this.props.info).path}>
          <Button style={buttonCss} fluid floated="left">
            <Icon name="chevron circle left" />
            <span className='redunderline'>{this.previousLink(this.props.info).context.title}</span>
          </Button>
        </Link>
        </Grid.Column>
        <Grid.Column width={8}>
        <Link to={this.upNextLink(this.props.info).path}>
          <Button style={buttonCss} fluid floated="right">
          <span className='redunderline'>{this.upNextLink(this.props.info).context.title}</span>
            <Icon name="chevron circle right" />
          </Button>
        </Link>
        </Grid.Column>
        </Grid>
    )
  }
}

export default PrevNext
