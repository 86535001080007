import React, { Component } from 'react'
import { connect } from 'react-redux'

import config from '../../config'
import ColorSwatch from './colorSwatch'
import QuantitySelector from './quantitySelector' 
import { Form, Divider, Grid } from 'semantic-ui-react'

class AddToCartButton extends Component {
  componentDidMount = () => {
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: 'productQuantity',
      value: 1,
    })
  }

  updateState = (item, value) => {
    this.props.dispatch({ type: 'UPDATE_STATE', item: item, value: value })
  }

  addToCart = product => {
    this.props.dispatch({ type: 'ADD_TO_CART', item: product })
    this.props.dispatch({ type: 'UPDATE_TOTALS' })
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: 'cartAnimation',
      value: !this.props.cartAnimation,
    })
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: 'miniCartAnimation',
      value: !this.props.miniCartAnimation,
    })
  }

  render() {
    let product = this.props.product //page context from gatsby-node

    return (
      <Form
        onSubmit={e => {
          this.addToCart({
            id: product.id,
            name: product.title,
            color: this.props.productColor,
            qty: this.props.productQuantity,
            price: Number(
              config.price * ((100 - config.siteDiscountPercent) / 100)
            ),
            total: this.props.productQuantity * config.price
          })
        }}
      >
        <Divider />
  
          <Grid columns='equal'>
            <Grid.Row>
            <Grid.Column >
            <Form.Input label="Available Colors:" fluid>
              <ColorSwatch product={product} />
            </Form.Input>
            </Grid.Column>
            <Grid.Column >
            <Form.Input label="Quantity:" fluid>
              <QuantitySelector />
            </Form.Input>
            </Grid.Column>
            </Grid.Row>
          </Grid>
        

        <Divider style={{margin: '20px'}} />
        <Form.Button
          disabled={!this.props.productColor}
          fluid
          size="large"
          data-cy="AddToCart"
          style={{
            backgroundColor: config.colors.secondary,
            color: config.colors.tertiary,
          }}
        >
          <h2>
            ${((config.price / 100) * this.props.productQuantity).toFixed(2)}
          </h2>{' '}
          Add to Cart
        </Form.Button>
      </Form>
    )
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    cartAnimation: state.cartAnimation,
    miniCartAnimation: state.miniCartAnimation,
    productQuantity: state.productQuantity,
    productColor: state.productColor,
  }
}

export default connect(mapStateToProps)(AddToCartButton)
