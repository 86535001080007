import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Icon } from 'semantic-ui-react'
import config from '../../config'

class QuantitySelector extends Component {
  updateState = (item, value) => {
    this.props.dispatch({ type: 'UPDATE_STATE', item: item, value: value })
  }

  render() {
    return (
      <Form.Group style={{ margin: '5px 0px 5px 0px' }}>
        <Icon
          style={{
            backgroundColor: config.colors.secondary,
            color: config.colors.tertiary,
          }}
          label="Quantity"
          circular
          size="large"
          name="minus"
          onClick={() => {
            if (this.props.productQuantity === 1) {
              this.updateState('productQuantity', this.props.productQuantity)
            } else {
              this.updateState(
                'productQuantity',
                this.props.productQuantity - 1
              )
            }
          }}
        />
        <h2 style={{ margin: '5px 20px 5px 20px', color: config.colors.primary  }}>
          {this.props.productQuantity}
        </h2>
        <Icon
          style={{
            backgroundColor: config.colors.secondary,
            color: config.colors.tertiary,
          }}
          circular
          size="large"
          name="plus"
          onClick={() =>
            this.updateState('productQuantity', this.props.productQuantity + 1)
          }
        />
      </Form.Group>
    )
  }
}

// export default QuantitySelector

const mapStateToProps = state => {
  return {
    productQuantity: state.productQuantity,
  }
}

export default connect(mapStateToProps)(QuantitySelector)
