import React from 'react'
import PropTypes from 'prop-types'
import { ShareButtonRectangle, ShareBlockStandard } from 'react-custom-share'
// import { css } from 'emotion'

import FaTwitter from 'react-icons/lib/fa/twitter'
import FaFacebook from 'react-icons/lib/fa/facebook'
import FaPinterest from 'react-icons/lib/fa/pinterest'
import FaLinkedin from 'react-icons/lib/fa/linkedin'

const TshirtShareBlock = props => {
  const { url, title, excerpt } = props

  // const customStyles = css`
  //   border-radius: 50% 0 50% 0;
  //   margin: 0 10px;
  //   flex-basis: 60px;
  //   height: 60px;
  //   flex-grow: 0;
  // `
  const shareBlockProps = {
    url: url,
    button: ShareButtonRectangle,
    buttons: [
      { network: 'Twitter', icon: FaTwitter },
      { network: 'Facebook', icon: FaFacebook },
      // { network: 'GooglePlus', icon: FaGooglePlus },
      // { network: 'Email', icon: FaEnvelope },
      {
        network: 'Pinterest',
        icon: FaPinterest,
        media: '..images/stripelogo.jpg',
      },
      { network: 'Linkedin', icon: FaLinkedin },
    ],
    text: title,
    longtext: excerpt,
    // buttonCustomClassName: customStyles,
  }

  return (
    
      <ShareBlockStandard {...shareBlockProps} />
    
  )
}

TshirtShareBlock.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
}

TshirtShareBlock.defaultProps = {
  url: 'https://www.funnyaftshirts.com',
  title: 'The funniest Shirts on the Internet',
  excerpt:
    'Take a look at this Tshirt I just found. Funny AF. Perfect for you!',
}

export default TshirtShareBlock
