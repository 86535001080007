import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'

import Img from 'gatsby-image'

class MainPic extends Component {
  state = { image: 'main' }

  updateImageState = image => {
    this.setState({ image: image })
  }

  updateImage = image => {
    let images = this.props.children
    if (image === 'leftSide') {
      return <Img fluid={images.leftSide[this.props.productColor].node.fluid} />
    } else if (image === 'rightSide') {
      return <Img fluid={images.rightSide[this.props.productColor].node.fluid} />
    } else if (image === 'back') {
      return <Img fluid={images.back[this.props.productColor].node.fluid} />
    } else {
      return <Img fluid={images.hatImage[this.props.productColor].node.fluid} />
    }
  }

  render() {
    let images = this.props.children
    return (
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>{this.updateImage(this.state.image)}</Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          <Grid.Column
            onClick={() => {
              this.updateImageState('hatImage')
            }}
          >
            <Img fluid={images.hatImage[this.props.productColor].node.fluid} />
          </Grid.Column>
          <Grid.Column
            onClick={() => {
              this.updateImageState('leftSide')
            }}
          >
            <Img fluid={images.leftSide[this.props.productColor].node.fluid} />
          </Grid.Column>
          <Grid.Column
            onClick={() => {
              this.updateImageState('back')
            }}
          >
            <Img fluid={images.back[this.props.productColor].node.fluid} />
          </Grid.Column>
          
          <Grid.Column
            onClick={() => {
              this.updateImageState('rightSide')
            }}
          >
            <Img fluid={images.rightSide[this.props.productColor].node.fluid} />
          </Grid.Column>
        </Grid.Row>
      </Grid>     
    )
  }
}

const mapStateToProps = state => {
  return {
    productColor: state.productColor,
  }
}

export default connect(mapStateToProps)(MainPic)
