import React, { Component } from 'react'
import { connect } from 'react-redux'
import config from '../../config'
import { Grid } from 'semantic-ui-react'

class ColorSwatch extends Component {
  state = { visible: true, color: 'white' }

  //need to create a true state for all colors to enable the animation
  // componentDidMount = () => {
  //   config.hatColors.map((color, key) => {
  //     this.setState({ [key]: true })
  //     return null
  //   })
  // }

  updateState = (item, value) => {
    this.props.dispatch({ type: 'UPDATE_STATE', item: item, value: value })
  }

  render() {
    const border = {
      margin: '3px',
      border: 'solid',
      borderWidth: 'thin',
      borderRadius: '1px',
      borderColor: config.colors.secondary,
    }

    return (
        <Grid>
          <Grid.Row style={{marginLeft: '12px'}}>
            <div style={border}>
              <div
                style={{
                  backgroundColor: config.colors.primary,
                  width: '42px',
                  height: '42px',
                }}
                onClick={() => this.updateState('productColor', '1')}
              />
            </div>
            <div style={border}>
              <div
                style={{
                  backgroundColor: config.colors.tertiary,
                  width: '42px',
                  height: '42px',
                }}
                onClick={() => this.updateState('productColor', '0')}
              />
            </div>
          </Grid.Row>
        </Grid>
    )
  }
}

const mapStateToProps = state => {
  return {
    productColor: state.productColor,
  }
}

export default connect(mapStateToProps)(ColorSwatch)
