import React from 'react'
import Layout from '../layouts/product'
import { StaticQuery, graphql } from 'gatsby'

import { Container, Grid, Divider, Header, Responsive } from 'semantic-ui-react'
import Helmet from 'react-helmet'

import TshirtShareBlock from '../components/product/TshirtShareBlock'
import ProductOptions from '../components/product/productOptions'
import PrevNext from '../components/product/prevNext'
import Quote from '../components/product/quote'
// import Pricing from '../components/product/pricing'
// import WtfBox from '../components/product/wtfbox'
import MainPic from '../components/product/mainPic'
import PicData from '../components/product/PicData'
import config from '../config.jsx'

const Product = ({ pageContext, data }) => (
  <Layout>
    <Helmet>
      <title>Make America {pageContext.title} Again!</title>
      <meta
        property="og:title"
        content={`Make America ${pageContext.title} Again!`}
      />
    </Helmet>
    <h1
      style={{
        marginTop: '120px',
        textAlign: 'center',
        fontSize: '7vmin',
        color: config.colors.primary,
      }}
    >
      Make America <span className="redunderline">{pageContext.title}</span>{' '}
      Again
    </h1>

    <Grid columns="equal" style={{ margin: '0' }}>
      <Grid.Column mobile={16} tablet={8} computer={8}>
        <PrevNext data={data.allSitePage.edges} info={pageContext.id - 1} />
        <PicData data={data} name={pageContext.title}>
          <MainPic />
        </PicData>
        <Divider />

        <Responsive maxWidth={'767'}>
          <ProductOptions textAlign="right" product={pageContext} data={data} />
        </Responsive>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={8} computer={8}>
        <Quote quote={pageContext.quote1} />
        <Container textAlign="justified">{pageContext.description}</Container>

        <ul>
          <strong>
            <li>Head circumference: 19 ¼” - 23 ⅝”</li>
          </strong>
          <li>100% washed cotton chino twill</li>
          <li>Unstructured, 6-panel, low-profile</li>
          <li>6 embroidered eyelets</li>
          <li>Adjustable strap with hide-away buckle</li>
          <li>Made in the USA</li>
        </ul>
        <Responsive minWidth={'768'}>
          <ProductOptions textAlign="right" product={pageContext} data={data} />
        </Responsive>
        <Header as="h1">Share with a Friend</Header>
        <TshirtShareBlock
          url={
            'https://www.makeamericahatsagain.com/hats/' +
            pageContext.title.toLowerCase()
          }
          title={pageContext.title}
          excerpt={
            "I think the 'Make America " +
            pageContext.title +
            " Again' hat is perfect for you."
          }
        />

        {/* <WtfBox tweets={pageContext.tweets} /> */}
      </Grid.Column>
    </Grid>
    <Container style={{ margin: '2vh' }} />
  </Layout>
)

export default props => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allImageSharp {
          edges {
            node {
              fluid(traceSVG: { color: "#BB133E" }) {
                aspectRatio
                tracedSVG
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        allSitePage(filter: { context: { title: { ne: null } } }) {
          edges {
            previous {
              path
              context {
                title
              }
            }
            node {
              path
              context {
                id
                title
                description
                quote1
              }
            }
            next {
              path
              context {
                title
              }
            }
          }
        }
      }
    `}
    render={data => <Product data={data} {...props} />}
  />
)
