import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'

class Quote extends Component {

  showQuote = () => {
    if (this.props.quote === undefined) {
        return null
      
    } else {
        return (<Container textAlign="justified"><i>{this.props.quote}</i><br/></Container>)
    }
  }

  render() {
    return (
        <div>
            {this.showQuote()}
            
        </div>
    )
  }
}

export default Quote
